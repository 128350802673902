import React from 'react'
import {BsLinkedin, BsGithub, BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/mohit-prajapat-31684b170/' target="_blank"><BsLinkedin /></a>
        <a href='https://github.com/MohitPrajapat007' target="_blank"><BsGithub /></a>
        <a href='https://www.instagram.com/97__mohit.prajapat/' target="_blank"><BsInstagram /></a>
    
    </div>
  )
}

export default HeaderSocials