import React from 'react'
import './Testimonials.css'

import AVTR1 from '../../assets/Client1.jpg'
import AVTR2 from '../../assets/Client2.png'
import AVTR3 from '../../assets/Client3.jpg'
import AVTR4 from '../../assets/Client5.jpg'

import { Pagination, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const data = [
  {
    avatar: AVTR1,
    name: 'Sundar Pichai',
    review: "A self-motivated team member and a pleasure to work with. He demonstrates superior technical ability and produces work of exceptional quality. He takes the time to fully understand the scope of projects and displays a keen attention to detail,but often at the expense of effective time management."
  },
  {
    avatar: AVTR2,
    name: 'Rajesh Gopinathan',
    review: "A self-motivated team member and a pleasure to work with. He demonstrates superior technical ability and produces work of exceptional quality. He takes the time to fully understand the scope of projects and displays a keen attention to detail,but often at the expense of effective time management."
  },
  {
    avatar: AVTR3,
    name: 'Timothy Donald Cook',
    review: "A self-motivated team member and a pleasure to work with. He demonstrates superior technical ability and produces work of exceptional quality. He takes the time to fully understand the scope of projects and displays a keen attention to detail,but often at the expense of effective time management."
  },
  {
    avatar: AVTR4,
    name: 'Jeff Bezos',
    review: "A self-motivated team member and a pleasure to work with. He demonstrates superior technical ability and produces work of exceptional quality. He takes the time to fully understand the scope of projects and displays a keen attention to detail,but often at the expense of effective time management."
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className='container testimonials__container'
      modules={[Pagination, Navigation]}
      spaceBetween={40}
      slidesPerView={1}
      navigation    
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review},index) => {
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src={avatar} />            
                </div>
                <h5 className='client__name'>{name}</h5>
                  <small className='client__review'>
                    {review}
                  </small>
              </SwiperSlide>
            )
          })
        }
        
      </Swiper>
    </section>
    
  )
}

export default Testimonials

