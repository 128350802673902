import React, { useState } from 'react'
import './Nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {FaUserGraduate} from 'react-icons/fa'
import {BsFillJournalBookmarkFill} from 'react-icons/bs'
import {RiServiceLine, RiContactsFill} from 'react-icons/ri'
 

const Nav = () => {

  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav>
        <a href='#' onClick={() => setActiveNav('#')} className={activeNav== '#' ? 'active': ''}><AiOutlineHome /></a>
        <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav == '#about' ? 'active' : ''}><FaUserGraduate /></a>
        <a href='#experience' onClick={() => setActiveNav('#experience')} className={activeNav == '#experience' ? 'active' : ''} ><BsFillJournalBookmarkFill /></a>
        <a href='#services' onClick={() => setActiveNav('#services')} className={activeNav == '#services' ? 'active' : ''}><RiServiceLine /></a>
        <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav == '#contact' ? 'active' : ''}><RiContactsFill /></a>
    </nav>
  )
}

export default Nav