import React from 'react'
import './About.css'
import ME from '../../assets/aboutme.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'



const About = () => {
  return (
    <section id='about'>
        <h5>Get To Know</h5>
        <h2>About Me</h2>

        <div className='container about__container'>
          <div className='about__me'>
            <div className='about__me-image'>
              <img src={ME} alt='About_Image' />
            </div>
          </div> 
          
          <div className='about__content'>
            <div className='about__cards'>
              <article className='about__card'>
                <FaAward className='about__icon'/>
                <h5>Experience</h5>
                <small>1+ Years Working</small>
              </article>

              <article className='about__card'>
                <FiUsers className='about__icon'/>
                <h5>Clients</h5>
                <small>40+ Worldwide</small>
              </article>

              <article className='about__card'>
                <VscFolderLibrary className='about__icon'/>
                <h5>Projects</h5>
                <small>3+ Completed</small>
              </article>
            </div>

            <p>
            I am a person who is positive about every aspect of life. There are many things I like to do, to see, 
            and to experience. I like to read, I like to write; I like to think, I like to dream; I like to talk, 
            I like to listen. I like to see the sunrise in the morning, 
            I like to see the moonlight at night; I like to feel the music flowing on my face, 
            I like to smell the wind coming from the ocean. I like to look at the clouds in the sky with a blank mind, I like to do thought experiment when 
            I cannot sleep in the middle of the night.
            </p>
            
            <a href='#contact' className='btn btn-primary'>Let's Talk</a> 
          </div>
        </div>

    </section>
  )
}

export default About