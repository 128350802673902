import React from 'react'
import CTA from './CTA'
import ME from './../../assets/myself.png'
import HeaderSocials from './HeaderSocials'
import './Header.css'

const Header = () => {
  return (
    <header>
        <div className='container header__container'>
          <h5>Hello I'am</h5>
          <h1>Mohit Prajapat</h1>
          <h5 className='text-light'>Front-End Developer</h5>
          <div className='cta'><CTA /></div>
          <HeaderSocials />
          
          <div className='me'>
              <img src={ME} alt='' />
          </div>
          <a href='#contact' className='scroll__down' >Scroll Down</a>
          
        </div>
    </header>
  )
}

export default Header