import React from 'react'
import './Contact.css'

import {AiOutlineMail} from 'react-icons/ai'
import {BsWhatsapp} from 'react-icons/bs'
import {AiOutlineInstagram} from 'react-icons/ai'


const Contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>Mohitprajapat456@gmail.com</h5>
            <a href='mailto:Mohitprajapat456@gmail.com' target='_blank'>Send a Message</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>Say Hello !!! to Me</h5>
            <a href='https://api.whatsapp.com/send?phone=+919521438210'  target='_blank'>Send a Message</a>
          </article>
          <article className='contact__option'>
            <AiOutlineInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>Send instant Hi !!!</h5>
            <a href='https://www.instagram.com/97__mohit.prajapat/'  target='_blank'>Send a Message</a>
          </article>
        </div>
        { /* CONTACT OPTIONS END */}

        <form action=''>
            <input type='text' name='name' placeholder='Your Full Name' required></input>
            <input type='email' name='email' placeholder='Your Email' required></input>
            <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact