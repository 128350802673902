import React from 'react'
import './Footer.css'

import {IoLogoTwitter} from 'react-icons/io'

import {AiOutlineInstagram, AiOutlineFacebook} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <div>
        <a href='#' className='footer__logo'>M-P Creation</a>

        <ul className='permalinks'>
          <li><a href='#'>Home</a></li>
          <li><a href='#about'>About</a></li>
          <li><a href='#experience'>Experience</a></li>
          <li><a href='#services'>Services</a></li>
          <li><a href='#portfolio'>Portfolio</a></li>
          <li><a href='#testimonials'>Testimonials</a></li>
          <li><a href='#contact'>Contact</a></li>
        </ul>

        <div className='footer__socials'>
          <a href='https://facebook.com'><AiOutlineFacebook /></a>
          <a href='https://instagram.com'><AiOutlineInstagram /></a>
          <a href='https://twitter.com'><IoLogoTwitter /></a>
        </div>

        <div className='footer__copyright'>
          <small>&copy; M-P Creation. All rights reserved</small>
        </div>

      </div>
    </footer>
  )
}

export default Footer