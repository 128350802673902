import React from 'react'
import portfolio1 from './../../assets/portfolio1.jpg'
import portfolio2 from './../../assets/portfolio2.jpg'
import portfolio3 from './../../assets/portfolio3.jpg'
import portfolio4 from './../../assets/portfolio4.jpg'
import portfolio5 from './../../assets/portfolio5.png'
import portfolio6 from './../../assets/portfolio6.jpg'

import './Portfolio.css'

const data = [
  {
    id: 1,
    image: portfolio1,
    title: "Facial Emotion Recognition and Detection",
    github:'https://github.com',
    demo:'https://leetcode.com'
  },
  {
    id: 2,
    image: portfolio2,
    title: "Evaluation of academic performance",
    github:'https://github.com',
    demo:'https://leetcode.com'
  },
  {
    id: 3,
    image: portfolio3,
    title: "Real-Time Weather Forecasting App",
    github:'https://github.com',
    demo:'https://codechef.com'
  },
  {
    id: 4,
    image: portfolio4,
    title: "Malware Analysis Sandbox",
    github:'https://github.com',
    demo:'https://hackerank.com'
  },
  {
    id: 5,
    image: portfolio5,
    title: "Optical Character Recognition (OCR) System",
    github:'https://github.com',
    demo:'https://geeksforgeeks.com'
  },
  {
    id: 6,
    image: portfolio6,
    title: "Keylogging App",
    github:'https://github.com',
    demo:'https://leetcode.com'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id,image,title,github,demo}) => {
            return(
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title}/>
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>            
              </article>
              )
            }
          )
        }
      </div>
    </section>
  )
}

export default Portfolio

