import React from 'react'
import Header from './components/Header/Header'
import Nav from './components/Nav/Nav'
import About from './components/AboutUs/About'
import Experience from './components/Experience/Experience'
import Contact from './components/Contact/Contact'
import Services from './components/Services/Services'
import Footer from './components/Footer/Footer'
import Portfolio from './components/Portfolio/Portfolio'
import Testimonials from './components/Testimonials/Testimonials'
import './App.css';

const App = () => {
  return (
    <div>
        <Header/>
        <Nav />
        <About />
        <Experience />
        <Services />
        <Portfolio />
        <Testimonials />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
